import React from "react";
import styled from "@emotion/styled";

import { NodePage } from "~/graphql";
import { PageSection } from "~/components/common";
import { fluidRange, respondFrom, breakpoints, css } from "~/styles";

import SEO from "~/components/layout/Seo";

/** styles */
const StyledSection = styled(PageSection)`
  ${fluidRange("paddingTop", "50px", "90px")}
  ${fluidRange("paddingBottom", "50px", "90px")}
  max-width: 700px;
  margin: 0 auto;

  h1 {
    margin-bottom: 1em;
  }
`;
const StyledContent = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      font-size: 16px;
    `
  )}

  h1, h2, h3, h4, h5 {
    margin-bottom: 0.5em;
  }
`;

interface Props {
  page: NodePage;
}
export const BasicPageContent: React.FC<Props> = props => {
  return (
    <StyledSection>
      <SEO />

      <h1>{props.page.title}</h1>
      <StyledContent
        dangerouslySetInnerHTML={{ __html: props.page.body || "" }}
      ></StyledContent>
    </StyledSection>
  );
};
export default BasicPageContent;
