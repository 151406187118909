import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Stage as StageType } from "konva/types/Stage";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { cardBackground, cardMascot, words, cardPreview, cardCaption } from "~/recoil";
import { CardPreview } from "../common/CardPreview";

import * as utils from "~/utils";

const ShareMessage = styled.div`
  margin-top: 15px;

  > p {
    margin: 10px 0;
  }
`;

interface Props {}
const CustomizationCardPreview: React.FC<Props> = () => {
  const background = useRecoilValue(cardBackground);
  const mascot = useRecoilValue(cardMascot);
  const selectedWords = useRecoilValue(words);
  const setCardPreview = useSetRecoilState(cardPreview);
  const caption = useRecoilValue(cardCaption);
  const stageRef = useRef<StageType>(null);

  // saving image data to store
  useEffect( () => {
    setTimeout(() => {
      stageRef.current && setCardPreview( stageRef.current?.getStage().toDataURL({
        mimeType: "image/jpeg",
        quality: 0.96,
        pixelRatio: 1.3,  // -> previewFactor
        // width: imgWidth,
        // height: imgHeight
      }) );
    }, 500);  // wait for full draw; TODO: events?
  }, [selectedWords, background, mascot, caption])

  return (
    <>
      <CardPreview 
        words={utils.changeWordsOrder([...selectedWords])}
        background={background}
        mascot={mascot}
        caption={caption}
        ref={stageRef}
      />
      <ShareMessage>
        <p>כדי להשתתף פעילות – אנא שתפו בפייסבוק והוסיפו לפוסט את ההאשטאג #תעבירו_מילה_טובה</p>
        <p>שיתוף באפליקציית WhatsApp אינו מהווה השתתפות בפעילות</p>
      </ShareMessage>
    </>
  )
};

export default CustomizationCardPreview;