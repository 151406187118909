import { PageProps } from "gatsby";
import React from "react";
import { useRecoilState } from "recoil";
import { Redirect } from '@reach/router';

import * as utils from "~/utils";
import SEO from "~/components/layout/Seo";
import CardCreator from "~/components/customize/CardCreator";
// import { ScrollIntoView } from "~/components/common/ScrollIntoView";
import { words as wordsState } from "~/recoil";

interface CustomizeProps extends PageProps {}
const Customize: React.FC<CustomizeProps> = () => {
  const [words,] = useRecoilState(wordsState);

  return (
    words.length ? <>
      <SEO />
      {/* <ScrollIntoView /> */}
      <CardCreator />
    </> : <Redirect to={utils.getPath('home') } noThrow />
  )
}
export default Customize;