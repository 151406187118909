import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import uuid from "react-uuid";

import { css, transitionDefault } from "~/styles";
import * as utils from "~/utils";
import { Button } from "~/components/common";
import { cardPreview } from "~/recoil";

const shareIcon = require("~/images/icons/share.svg") as string;
const loaderSvg = require("~/images/loader.svg") as string;


const StyledButton = styled(Button)<StylesProps>`
  width: auto;
  margin-bottom: 10px;
  padding-right: 35px;

  img {
    width: 15px;
    height: 14px;
    max-width: none;
    position: absolute;

    ${transitionDefault};

    &.loader-icon {
      animation: keyframesSelfRotate90 1000ms ease-in-out alternate infinite;
      opacity: 0;
    }
  }

  ${props => props.isSending ? css`
    img.loader-icon {
      opacity: 1;
    }
    img.share-icon {
      opacity: 0;
    }
  `: css``}
`;


const dataURItoBlob = (dataURI, dataTYPE) => {
  var binary = atob(dataURI.split(",")[1]),
    array: any[] = [];
  for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
  return new Blob([new Uint8Array(array)], { type: dataTYPE });
};

interface StylesProps {
  isSending?: boolean;
}
interface Props {
  onSent: (shareUrl: string) => void;
  alreadySent: boolean;
}
export const ShareButton: React.FC<Props> = ({ onSent, alreadySent }) => {
  const cardPreviewUri = useRecoilValue(cardPreview);
  const [isSending, setSending] = useState(false);

  const share = useCallback(() => {
    utils.dataLayerShare('share_btn');
    const file = dataURItoBlob(cardPreviewUri, "text/jpeg");
    const filename = uuid();

    const options = {
      method: "POST",
      headers: {
        "Content-Disposition": `file; filename="${uuid()}.jpg"`,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    setSending(true);
    fetch(
      `${utils.getApiPath()}/file/upload/node/entry_message/field_em_image?_format=json`,
      options
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // TODO: interface
        // console.debug(resultData);
        // console.log(`${utils.getApiPath()}${resultData.uri[0].url}`);
        createEntryMsg(resultData.fid[0].value, filename, `${resultData.uri[0].url}`);
      })
      .catch((err) => {
        console.error(err);
        setSending(false);
      });
  }, [cardPreviewUri]);

  const cardPath = utils.getPath('card');
  const createEntryMsg = useCallback(
    (fileId: string, filename: string, redirectUrl: string) => {
      
      const domain = utils.isInIframe() ? document.referrer : 
        process.env.APP_ROOT;
        
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: [{ target_id: "entry_message" }],
          title: [{ value: filename }],
          field_em_image: [{ target_id: fileId }],
          field_em_pills: [],
          // field_em_redirect_url: [{ value: `${domain}${utils.isInIframe() ? '?' : ''}${cardPath}/?target=${redirectUrl}` }]
          field_em_redirect_url: [{ value: `https://tictacgoodword.co.il` }]
        }),
      };

      fetch(`${utils.getApiPath()}/node?_format=json`, options)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setSending(false);
          console.debug(resultData);

          onSent(`${utils.getApiPublicPath()}/he/share/message/${resultData.uuid[0].value}`);
        })
        .catch(() => {
          setSending(false);
        });
    },
    [cardPreviewUri]
  );

  return (
    <>
      <StyledButton onClick={share} disabled={isSending || alreadySent} isSending={isSending}>
        { utils.t('Share') } &nbsp;
        <img className="share-icon" src={shareIcon} alt="" />
        <img className="loader-icon" src={loaderSvg} alt="" />
      </StyledButton>
    </>
  );
};
