/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";

import {
  colors,
  respondFrom,
  breakpoints,
  css,
  transitionDefault,
} from "~/styles";
import * as utils from "~/utils";
import BasicPageContent from "~/components/basicPage";
import { Button } from "~/components/common";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 200ms linear;

  &.is-open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
`;

const ModalInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: ${colors.darkpalegreen};
  color: ${colors.white};

  padding: 80px 30px 35px;
  overflow: auto;

  ${respondFrom(
    breakpoints.md,
    css`
      position: relative;
      width: 90%;
      height: auto;
      max-height: calc(100vh - 40px);
      max-width: 750px;
      padding: 65px 50px 35px;
      border-radius: 10px;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 100%;
      max-height: calc(100vh - 80px);
    `
  )}

  section {
    padding: 0;
  }
`;

const ModalClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px 35px;
  background: transparent;
  border: none;
  height: 25px;
  width: 25px;
  cursor: pointer;
  opacity: 0.5;

  ${transitionDefault};

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 5px;
    height: 25px;
    background-color: ${colors.white};
    transform: rotate(45deg);
    border-radius: 3px;
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.8;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 30px 30px;
    `
  )}
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const StyledButton = styled(Button)`
  width: auto;
  min-width: 200px;
`;

interface ModalProps {
  content?: any;
  isOpened: boolean;
  onCloseModalPanel?: Function;
  basicPageTitle?: string;
  bottomCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = props => {

  useEffect(() => {
    if (props.isOpened) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [props.isOpened]);

  const onCloseModal = useCallback(() => {
    if (props.onCloseModalPanel) {
      props.onCloseModalPanel();
    }
  }, []);

  const onClickOutsideModal = useCallback(e => {
    if (
      [...e.target.classList].find(className => className === "modal-wrapper")
    ) {
      onCloseModal();
    }
  }, []);

  const onKeypressEscape = useCallback(e => {
    if (e.key === "Escape") {
      onCloseModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", onKeypressEscape);

    return function cleanup() {
      document.removeEventListener("keyup", onKeypressEscape);
    };
  }, []);

  return (
    <ModalWrapper
      className={props.isOpened ? "modal-wrapper is-open" : "modal-wrapper"}
      onClick={e => onClickOutsideModal(e)}
    >
      <ModalInner>
        <ModalClose onClick={onCloseModal} />
        <div>{props.content}</div>

        {props.bottomCloseButton && (
          <ButtonContainer>
            <StyledButton onClick={onCloseModal} secondary>
              { utils.t('Close') }
            </StyledButton>
          </ButtonContainer>
        )}
      </ModalInner>
    </ModalWrapper>
  );
};

export default Modal;
