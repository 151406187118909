import React from 'react';
import { useRecoilState } from 'recoil';

import * as utils from "~/utils";
import { cardCaption } from "~/recoil";
import { TextInput } from "~/components/common";
import { consts } from "~/utils";

export const NameInput: React.FC<{}> = () => {
  const [caption, setCaption] = useRecoilState(cardCaption);

  return (
    <TextInput 
      label={ utils.t('Your signature (optional)') }
      placeholder={ utils.t('Name') }
      maxLength={consts.NAME_MAX_CHARS} 
      value={caption}
      onChange={(e) => setCaption(e.target.value)}
    />
  )
};
