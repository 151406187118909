import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import ActionPill from '~/components/customize/ActionPill';
import * as animationData from '~/images/lottie-svg/happy.json';
import { useSetRecoilState } from 'recoil';
import { messageSet } from '~/recoil';
import * as utils from "~/utils";
import { breakpoints, respondTo, css } from "~/styles";

const StyledActionPill = styled(ActionPill)`
  ${respondTo(
    breakpoints.md,
    css`
      margin-top: 20px;
    `
  )} 
`;

export interface ActionPillRandomProps {}
const ActionPillRandom: React.FC<ActionPillRandomProps> = () => {

  const setMessageState = useSetRecoilState(messageSet);

  const handleClick = useCallback(() => {
    utils.setMessage(utils.getRandomPreparedMessage(), setMessageState, false);
    utils.scrollToMainContent(true);
  }, []);

  return (
    <StyledActionPill rotate={-6}
      header={ utils.t('Try a random generated gentle message') }
      animationData={animationData}
      onClick={handleClick}
    />
  );
}

export default ActionPillRandom;
