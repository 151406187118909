import React, { useState, useEffect, useCallback } from 'react';
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil/dist";

import * as utils from "~/utils";
import { ShareButton } from "./ShareButton";
import { Button } from "~/components/common";
import { SocialMediaButtons } from "./SocialMediaButtons";
import { transitionDefault, css } from '~/styles';
import { cardPreview, cardBackground, cardMascot, words as cardWords, cardCaption } from "~/recoil";

const StyledButton = styled(Button)`
  width: auto;
  margin-bottom: 10px;
  align-self: flex-start;
`;
const StyledShareButtonsCont = styled.div<StylesProps>`
  width: 126px;
  text-align: center;

  > div {
    padding-top: 0;
    margin-bottom: 15px;
  }

  opacity: 0;
  visibility: hidden;
  max-height: 0;
  ${transitionDefault};
  transition-property: opacity, visibility, max-height;

  ${props => props.shareButtonsVisible ? css`
    opacity: 1;
    visibility: visible;
    max-height: 65px;
  ` : null}
`

const ShareButtonContainer = styled.div`
  display: inline-block;
  text-align: left;
`

interface StylesProps {
  shareButtonsVisible: boolean;
}

export const CardCustomizationButtons: React.FC<{}> = () => {
  const cardPreviewUri = useRecoilValue(cardPreview);
  const [shareUrl, setShareUrl] = useState<string>('');
  const [cardChanged, setCardChanged] = useState(false);

  const background = useRecoilValue(cardBackground);
  const mascot = useRecoilValue(cardMascot);
  const words = useRecoilValue(cardWords);
  const caption = useRecoilValue(cardCaption);



  useEffect(() => {
    setCardChanged(true);
  }, [words, background, mascot, caption])

  const cardSent = useCallback((shareUrl: string) => {
    setShareUrl(shareUrl);
    setCardChanged(false);
  }, []);

  const saveAsImage = () => {
    utils.saveUriToFile(
      cardPreviewUri,
      `tictac-gentle-message-${Date.now()}.jpg`
    );
  };

  return <>
    <StyledButton onClick={saveAsImage} secondary>
      { utils.t('Save as image') }
    </StyledButton>
    <ShareButtonContainer>
      <ShareButton onSent={cardSent} alreadySent={!!shareUrl && !cardChanged} />
      {
        <StyledShareButtonsCont shareButtonsVisible={!!shareUrl && !cardChanged}>
          <div>
            <SocialMediaButtons url={shareUrl} />
          </div>
        </StyledShareButtonsCont>
      }
    </ShareButtonContainer>
  </>
};
