import * as React from 'react';
import styled from "@emotion/styled"; 

import { colors, css, dimensions } from "~/styles";
import { Stylable } from "~/content/types";


/** styles */
const StyledInput = styled.input`
  background: ${colors.white};
  color: ${colors.darkgrey};
  padding: 15px 24px 14px;
  border-radius: 8px;
  outline: none;
  border: none;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-size: ${dimensions.fontSize.regular}px;
  text-align: center;

  &::placeholder {
    /* opacity: 0.7; */
  }
`
const StyledLabel = styled.label`
  text-transform: uppercase;
  color: ${colors.darkgreen};
  font-size: ${dimensions.fontSize.small}px;
  font-weight: bold;
  margin-bottom: 6px;
  display: inline-block;
`


export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement|HTMLTextAreaElement>, Stylable {
  label?: string;
}

const TextInput: React.FC<TextInputProps> = ({ className,  ...props }) => {
  return ( 
    <>
      {
        props.label && 
        <StyledLabel>&rlm;{props.label}</StyledLabel>
      }
      <StyledInput className={className} {...props} />
    </>
  );
}
 
export default TextInput;
export { TextInput };