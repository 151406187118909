import React, { useEffect } from "react";
import styled from "@emotion/styled";
import {colors, dimensions, border} from "~/styles";
import {css} from "@emotion/core";
import { TextValue } from "~/content/types";


const StyledInput = styled.div`
  
`
const StyledLabel = styled.label`
  text-transform: uppercase;
  color: ${colors.darkgreen};
  font-size: ${dimensions.fontSize.small}px;
  font-weight: bold;
`
const StyledSelectArea = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 320px; */
  height: 45px;
  margin-top: 6px;
  margin-bottom: 15px;
  border-radius: ${border.radius.regular}px;
  background-color: ${colors.white};
  color: ${colors.darkgrey};
  font-size: ${dimensions.fontSize.small}px;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-size: ${dimensions.fontSize.regular}px;
  }
`

const StyledArrow = styled.div<StylesProps>`
  &:before {
    border-style: solid;
    border-width: 0.20em 0.20em 0 0;
    content: '';
    display: inline-block;
    height: 0.70em;
    transform: rotate(-135deg);
    vertical-align: top;
    width: 0.70em;
  }

  padding: 10px;
  position: absolute;
  left: 5px;
  top: 6px;
  cursor: pointer;

  ${props => props.flip && css`
    left: auto;
    right: 5px;
    transform: scaleX(-1);
  `}
`

interface StylesProps {
  flip?: boolean;
}
interface Props extends StylesProps {
  label: string;
  items: TextValue[];
  onChange: (value: string) => void;
  value: string;
}
const HorizontalSelect: React.FC<Props> = props => {
  const [currentItemIndex, setCurrentItemIndex] = React.useState(0);

  useEffect(() => {
    updateSelectedItem( props.items.findIndex( i => i.value === props.value ) );
  }, [props.value]);

  const updateSelectedItem = (index) => {
    setCurrentItemIndex(index);
    props.onChange(props.items[index < 0 ? 0 : index].value);
  };

  const previousItem = () => {
    let index;

    if (currentItemIndex - 1 < 0) {
      index = props.items.length - 1
    } else {
      index = currentItemIndex - 1;
    }

    updateSelectedItem(index)
  };

  const nextItem = () => {
    let index;

    if (currentItemIndex + 1 >= props.items.length) {
      index = 0
    } else {
      index = currentItemIndex + 1;
    }

    updateSelectedItem(index)
  };

  return (
    <StyledInput>
      <StyledLabel>{props.label}</StyledLabel>
      <StyledSelectArea>
        <StyledArrow onClick={previousItem}/>
        <span>{props.items[currentItemIndex < 0 ? 0 : currentItemIndex].text}</span>
        <StyledArrow flip onClick={nextItem}/>
      </StyledSelectArea>
    </StyledInput>
  )
};

export default HorizontalSelect;