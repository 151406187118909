import * as React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/core";

import {PageSection} from "~/components/common";
import {breakpoints, fluidRange, respondTo, respondFrom} from "~/styles";
import CustomizationCardPreview from "~/components/customize/CustomizationCardPreview";
import CardCustomizationForm from "~/components/customize/CardCustomizationForm";
import ActionPillAgain from "./ActionPillAgain";
import ActionPillRandom from "./ActionPillRandom";

interface Props {
}

/** styles */
const StyledSection = styled(PageSection)`
  ${respondFrom(
    breakpoints.md,
    css`
      ${fluidRange('paddingBottom', '50px', '90px')}
      display: flex;
      flex-direction: row;
      width: 100%;
    `
  )}

  &.pills-wrapper {
    ${respondFrom(
      breakpoints.md,
      css`
        flex-direction: row-reverse;
      `
    )}
  }
`;

const StyledCol = styled.div`
  display: inline-block;
  width: 100%;
  ${fluidRange('paddingBottom', '30px', '60px', breakpoints.xs, breakpoints.md)}

  ${respondFrom(
    breakpoints.md,
    css`
      width: 49%;
      padding-bottom: 0;
    `
  )} 
`;

const CardCreator: React.FC<Props> = props => (
  <>
    <StyledSection>
      <StyledCol>
        <CustomizationCardPreview/>
      </StyledCol>
      <StyledCol>
        <CardCustomizationForm/>
      </StyledCol>
    </StyledSection>
    <StyledSection className="pills-wrapper">
      <StyledCol>
        <ActionPillRandom />
      </StyledCol>
      <StyledCol>
        <ActionPillAgain />
      </StyledCol>
    </StyledSection>
  </>
)

export default CardCreator;
