import React from 'react';
import styled from "@emotion/styled";
import { transitionDefault } from "~/styles";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { buttonShadow } from "~/components/common/Button";
import * as utils from "~/utils";

const StyledCont = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    outline: none;
    border: none;
    margin: 0 5px;
  }

  /* > *:last-of-type span {
    margin-right: 0;
  } */
`

const StyledButton = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  background: none;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  ${buttonShadow};

  ${transitionDefault};

  &:hover {
    transform: translateY(-1px);
  }
`
const ButtonFb = styled(StyledButton)`
  background-image: url('/images/share-fb.svg');
`
const ButtonTw = styled(StyledButton)`
  background-image: url('/images/share-tw.svg');
`
const ButtonMsgr = styled(StyledButton)`
  background-image: url('/images/share-msgr.svg');
`
const ButtonWhatsapp = styled(StyledButton)`
  background-image: url('/images/share-wa.svg');
`

interface Props {
  url: string;
}
export const SocialMediaButtons: React.FC<Props> = ({ url }) => {
  return (
    <StyledCont>
      <FacebookShareButton url={url} onClick={() => { utils.dataLayerShare('share_facebook') }}>
        <ButtonFb />
      </FacebookShareButton>
      <WhatsappShareButton url={url} onClick={() => { utils.dataLayerShare('share_whatsupp') }}>
        <ButtonWhatsapp />
      </WhatsappShareButton>
    </StyledCont>
  );
}