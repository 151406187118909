import React, { useContext, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import * as utils from "~/utils";
import HorizontalSelect from "~/components/common/HorizontalSelect";
import { dimensions, respondFrom, fonts, breakpoints, css } from "~/styles";
import { cardBackground, cardMascot } from "~/recoil";
import { useRecoilState } from "recoil/dist";
import { NameInput } from "~/components/index/NameInput";
import { CardCustomizationButtons } from "~/components/customize/CardCustomizationButtons";
import Modal from "../layout/Modal";
import localeContext from "~/localeContext";
import BasicPageContent from "../basicPage";

interface Props {}

const backgrounds = [
  { text: "Orange", value: "orange" },
  { text: "Green", value: "green" },
  { text: "Dark Green", value: "dark_green" },
  { text: "Blue", value: "blue" },
];
const mascots = [
  { text: "חתול", value: "catgift" },
  { text: "מלאך וליצן", value: "angelclown" },
  { text: "אלביס", value: "elvisdance" },
  { text: "חברות", value: "ghostgift" },
  { text: "גיבור על", value: "superheroboy" },
];

//styles
const StyledContainer = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      padding-left: 30px;
      margin-right: 30px;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-left: 60px;
      max-width: 400px;
      margin-right: 100px;
    `
  )}
`;

const StyledContainerInner = styled.div`
  margin: auto;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 320px;
      margin: 0;
    `
  )}
`;

const StyledHeading = styled.h3`
  font-family: ${fonts.headersLight};
  font-weight: bold;
  text-transform: none;
  font-size: 27px;
  margin-bottom: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
`;
const StyledDisclaimer = styled.p`
  font-size: ${dimensions.fontSize.small}px;
  margin: 25px 0;
`;
const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const CardCustomizationForm: React.FC<Props> = () => {
  const [background, setBackground] = useRecoilState(cardBackground);
  const [mascot, setMascot] = useRecoilState(cardMascot);
  const [modalOpened, setModalOpened] = useState(false);
  const { basicPages } = useContext(localeContext);

  const backgroundsT = useMemo(() => {
    return backgrounds.map( ({ text, value }) => ({
      text: utils.t(text),
      value
    }))
  }, []);
  const mascotsT = useMemo(() => {
    return mascots.map( ({ text, value }) => ({
      text: utils.t(text),
      value
    }))
  }, []);


  const termsAndConditionsPage = useMemo(() => {
    return basicPages.find(p => p.field_terms_of_use === '1')
  }, []);

  const onChangeBackground = (value: string) => {
    setBackground(value);
  };

  const onChangeMascot = (value: string) => {
    setMascot(value);
  };

  const openModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setModalOpened(true);
  }

  const setModalClosed = () => {
    setModalOpened(false);
  }

  return (
    <>
      <StyledContainer>
        <StyledHeading>{ utils.t('Personalize your card') }</StyledHeading>
        <StyledContainerInner>
          <NameInput />
          <HorizontalSelect
            label={ utils.t('Background') }
            items={backgroundsT}
            onChange={onChangeBackground}
            value={background}
          />
          <HorizontalSelect
            label={ utils.t('Mascots') }
            items={mascotsT}
            onChange={onChangeMascot}
            value={mascot}
          />
          <StyledDisclaimer>
            { utils.t('Using the website is considered as an agreement to the') }{" "}
            {
              termsAndConditionsPage ?
                <>
                  <Link to={ utils.getPath( utils.slugify(termsAndConditionsPage.title) ) } onClick={openModal}>
                    { utils.t('terms & conditions') }
                  </Link>.
                </> :
                <>
                  { utils.t('terms & conditions') }.
                </>
            }
          </StyledDisclaimer>
          <StyledActions>
            <CardCustomizationButtons />
          </StyledActions>
        </StyledContainerInner>
      </StyledContainer>

      {
        termsAndConditionsPage ?
        <Modal isOpened={modalOpened}
          onCloseModalPanel={setModalClosed}
          content={ <BasicPageContent page={termsAndConditionsPage} /> }
          bottomCloseButton={true}
        /> : null
      }
    </>
  );
};

export default CardCustomizationForm;
